<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-0">
                {{ "Đăng nhập" }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-form ref="form" lazy-validation v-model="valid">
              <v-text-field
                color="secondary"
                v-model="form.username"
                :disabled="loading"
                label="Tên đăng nhập"
                :rules="rule.nameRules"
                prepend-icon="mdi-account"
              />

              <v-text-field
                class="mb-8"
                :append-icon="!show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                color="secondary"
                v-model="form.password"
                :disabled="loading"
                :rules="rule.passwordRules"
                label="Mật khẩu"
                @keyup.enter="login"
                prepend-icon="mdi-lock-outline"
                :type="show ? 'text' : 'password'"
              />
            </v-form>

            <pages-btn
              large
              color
              depressed
              @click="login"
              :loading="loading"
              class="v-btn--text success--text"
              >Đăng nhập</pages-btn
            >
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("@/layouts/pages/components/Btn"),
  },

  data: () => ({
    valid: true,
    show: false,
    loading: false,
    form: {
      username: "",
      password: "",
    },
    rule: {
      nameRules: [(v) => !!v || "Bạn chưa nhập tên đăng nhập"],
      passwordRules: [(v) => !!v || "Bạn chưa nhập password"],
    },
    socials: [
      {
        href: "#",
        icon: "mdi-facebook",
      },
      {
        href: "#",
        icon: "mdi-twitter",
      },
      {
        href: "#",
        icon: "mdi-github",
      },
    ],
  }),
  methods: {
    login() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;

      this.$store
        .dispatch("user/login", this.form)
        .then((homeUrl) => {
          this.$router.push(homeUrl);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.error === "Invalid Credentials") {
            this.$snackbar("Sai tài khoản hoặc mật khẩu", "error");
            this.loading = false;
          }
        });
    },
  },
};
</script>
